<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-cliente-dialog :item="item" v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <cliente-logado-data-table ref="dataTable"
                           @click:edit="editar"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import ClienteLogadoDataTable from "../../components/shared/datatables/ClienteLogadoDataTable";
import BaseView from "../BaseView";
import CreateEditClienteDialog from "../../components/shared/dialogs/CreateEditClienteDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditClienteDialog, BaseView, ClienteLogadoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Nome',
          column: 'nome',
          type: 'text'
        }
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(cliente) {
      this.item = JSON.parse(JSON.stringify(cliente));
      this.editDialogOpened = true;
    }
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}
</style>